import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import Activity9Photos from "../shared/program/Activity9Photos"
import HeadingM from "../shared/HeadingM"

const query = graphql`
  query {
    activity01: file(relativePath: { eq: "do/activity/activity-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    activity02: file(relativePath: { eq: "do/activity/activity-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    activity03: file(relativePath: { eq: "do/activity/activity-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    activity04: file(relativePath: { eq: "do/activity/activity-04.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    activity05: file(relativePath: { eq: "do/activity/activity-05.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    activity06: file(relativePath: { eq: "do/activity/activity-06.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    activity07: file(relativePath: { eq: "do/activity/activity-07.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    activity08: file(relativePath: { eq: "do/activity/activity-08.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    activity09: file(relativePath: { eq: "do/activity/activity-09.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
  }
`

const ProgramActivityExample = () => {
  const data = useStaticQuery(query)
  const items = [
    {
      image: getImage(data.activity01),
    },
    {
      image: getImage(data.activity02),
    },
    {
      image: getImage(data.activity03),
    },
    {
      image: getImage(data.activity04),
    },
    {
      image: getImage(data.activity05),
    },
    {
      image: getImage(data.activity06),
    },
    {
      image: getImage(data.activity07),
    },
    {
      image: getImage(data.activity08),
    },
    {
      image: getImage(data.activity09),
    },
  ]
  return (
    <div>
      <HeadingM title="活動事例" target="do" />
      <Activity9Photos items={items} />
    </div>
  )
}

export default ProgramActivityExample
