import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"

type Props = {
  items: {
    image: IGatsbyImageData
  }[]
}

const Activity9Photos: React.VFC<Props> = ({ items }) => {
  return (
    <div className="flex justify-between md:flex-row flex-wrap gap-8 mt-12 md:mt-16">
      {items.map((item) => {
        return (
          <div className="md:w-1/4">
            <GatsbyImage
              image={item.image}
              alt="activity example"
              className="rounded-3xl"
            />
          </div>
        )
      })}
    </div>
  )
}

export default Activity9Photos
